import { DocumentChartBarIcon, TicketIcon, UsersIcon, DocumentTextIcon } from "@heroicons/react/24/outline";
import routes from "../../routes";
import Roles from "../pages/Roles";
import Users from "../pages/Users";
import Support from "../../ui-components/pages/Support";
import AuditFrontend from "../pages/AuditFrontend";
import AuditBackend from "../pages/AuditBackend";
import TermsAndConditions from "../pages/TermsAndConditions";
import PrivacyPolicy from "../pages/PrivacyPolicy";

import { ENABLE_TERM_AND_CONDITIONS_PAGE, ENABLE_PRIVACY_POLICY_PAGE } from "@utils/constants";

const menu = [
  ...routes,
  {
    name: "users_settings",
    Icon: UsersIcon,
    dropdown: [
      {
        scopes: ["user:read"],
        href: "/users",
        name: "users",
        component: Users,
      },
      {
        scopes: ["role:update"],
        href: "/roles",
        name: "roles",
        component: Roles,
      },
    ],
  },
  {
    name: "audit",
    Icon: DocumentChartBarIcon,
    dropdown: [
      {
        scopes: ["audit:read"],
        href: "/audit/frontend",
        name: "frontend",
        component: AuditFrontend,
      },
      {
        scopes: ["audit:read"],
        href: "/audit/backend",
        name: "backend",
        component: AuditBackend,
      },
    ],
  },
  {
    name: "terms_and_privacy",
    Icon: DocumentTextIcon,
    dropdown: [
      {
        href: "/terms/terms_and_conditions",
        name: "terms_and_conditions",
        component: TermsAndConditions,
        hidden: !ENABLE_TERM_AND_CONDITIONS_PAGE,
      },
      {
        href: "/terms/privacy_policy",
        name: "privacy_policy",
        component: PrivacyPolicy,
        hidden: !ENABLE_PRIVACY_POLICY_PAGE,
      },
    ],
  },
  {
    name: "support",
    href: "/support",
    Icon: TicketIcon,
    component: Support,
  },
];

export default menu;
