import en_faq from "./faq/en_faq";
import en_form from "./form/en";

const en = {
  no_options_available: "No options available",
  entity_user: "Management of users in the system",
  entity_role: "Management of authorization roles in the system",
  entity_audit: "Management of audit in the system",
  name_and_surname: "Name and surname",
  Forecast: "Forecast",
  language_it: "Italiano",
  language_en: "English",
  language: "Language",
  Hedging: "Hedging",
  sign_in: "Login",
  mfa: "Multi Factor Authentication",
  Home: "Home",
  client_sso: "client sso",
  accenture_sso: "accenture sso",
  disabled_user: "Disabled user",
  enter_name_and_surname: "Enter your name and surname",
  enter_name_or_email: "Enter email and name",
  need_to_select_a_role: "You must select a role",
  delete_user: "Delete the user?",
  disabled: "Disabled",
  user_updated_successfully: "User updated successfully",
  user_deleted_successfully: "User successfully deleted",
  user_created_successfully: "User successfully created",
  description: "Description",
  role_name: "Role name",
  add_role_name: "Enter the name of the role",
  add_role: "Add role",
  add_user: "Add user",
  deleted_role: "Role deleted",
  updated: "Updated",
  delete_element: "Are you sure you want to delete the element?",
  role_already_used: "Role already in use!",
  role_created_successfully: "Role created successfully",
  add: "Add",
  server_error: "Server Error",
  confirm_new_password: "Confirm password",
  passwords_not_matching: "The two passwords do not match",
  password_without_spaces: "The password must not contain spaces",
  password_length: "The password must be at least 8 characters long",
  signup: "Register",
  error_while_saving: "Error while saving",
  authentication_failed: "Authentication failed",
  send: "Send",
  user_not_found: "User not found",
  wrong_old_password: "Wrong old password",
  enter_actual_password: "Enter current password",
  actual_password: "Current password",
  new_password: "New password",
  email: "Email",
  name: "Name",
  role: "Role",
  user_type: "User type",
  my_profile: "My profile",
  access_denied: "Access denied",
  password_updated_successfully: "Password changed successfully",
  submit_password: "Send password",
  enter_your_email_address: "Enter the email address associated with your user",
  restore_password: "Recover password",
  forgot_password: "Recover password",
  edit_password: "Change password",
  show_password: "Show password",
  form_error: "Check the form for errors",
  enter_email: "Enter email",
  missing_sender_mail: "Missing sender mail",
  log_into_your_account: "Log into your account",
  wrong_email_or_password: "Wrong email or password. Attempts left: {number}",
  wrong_otp: "Otp error",
  user_locked: "User account locked because of too many failed attempts, please wait 30 mintues",
  password_expired: "Password expired, reset it using the button \"Recover password\"",
  password_expiring_title: "Password expiring!",
  password_expiring: "Password is going to expire in {days} days",
  unregistered_email: "Email not registered",
  forgot_password_msg: "Mail sent! If you don't hear from us in the next 15 minutes, please double check that you entered the correct email address and check your spam folder.",
  roles: "Roles",
  roles_displayed_rows: "{from}-{to} of {count} roles",
  users: "Users",
  users_displayed_rows: "{from}-{to} of {count} users",
  components: "Components",
  maps: "Maps",
  tables: "Tables",
  graphs: "Graphs",
  users_settings: "User management",
  welcome: "Welcome",
  test: "test_it",
  audit: "Audit",
  frontend: "Frontend",
  backend: "Backend",
  file_picker_placeholder: "Select one or more files",
  success_ticket_sent: "The ticket was added successfully, we will take the request in charge as soon as possibile.",
  hubspot_portal: "Hubspot Customer Portal",
  size_limit_exceeded: "Size limit exceeded",
  customer_portal_loading_failed: "Customer portal link loading error",
  week_datepicker: "Week ",
  week_datepicker_not_selected: "Weekly Datepicker",
  daily_datepicker_not_selected: "Daily Datepicker",
  thanks: "Thanks!",
  select_an_option: "Please select an option from the dropdown menu.",
  ticket_parameters_not_found: "Ticket parameters not found",
  troisi_parameters_not_found: "Mail parameters not found",
  error_new_ticket_creation: "An error occurred while creating a new ticket",
  error_ticket_hubspot_management: "New ticket is created, but went wrong during the operations made by our portal",
  error_ticket_file_attachment: "New ticket is created, but it's not possibile to attach the file specified",
  session_expired: "Session expired",
  refreshing_session_wait: "Refreshing session, please wait",
  email_already_in_use: "Email already in use",
  mfa_guide_guide1_title: "Why do I need it?",
  mfa_guide_guide1_text: "Two-factor authentication (2FA) adds an extra layer of security to your account. Even if someone manages to guess your password, they won't be able to access your account without the verification code set up on your device.",
  mfa_guide_guide2_title: "What do I need for authentication?",
  mfa_guide_guide2_text: "You need to download a special app for multi-factor authentication on your smartphone. Here's how:",
  mfa_guide_guide2_text_item1: "Download an authenticator app, such as Google Authenticator or Microsoft Authenticator, from the App Store or Play Store",
  mfa_guide_guide2_text_item2: "Open the app and add an account. In the case of Microsoft Authenticator, you must first select “work or school account”",
  mfa_guide_guide2_text_item3: "To connect your app to your account on the platform, you now simply need to frame the QR code from the application, or enter the numeric code",
  mfa_guide_guide2_text_item4: "If the application shows the 6-digit passcode, you are done installing!",
  mfa_guide_guide3_title: "I can't access the app",
  mfa_guide_guide3_text: "If the verification code is not accepted, please contact the administrator of the platform at the following address: {address}",
  authentication_method: "Authentication method",
  auth_method_accenture_eso: "Accenture SSO",
  auth_method_client_sso: "{client} SSO",
  auth_method_standard_login: "Email and password",
  generic_error: "Unexpected error",
  terms_and_conditions: "Terms and conditions",
  terms_and_privacy: "Terms and privacy",
  privacy_policy: "Privacy policy",
  ...en_faq,
  ...en_form,
};

export default en;
